import React, { useState } from "react";
import Seo from "../components/seo";
const Works = () => {
	const password = "hireatina";
	const [input, setInput] = useState("");
	const [error, setError] = useState();

	const handleInputChange = (e) => {
		const { value } = e.target;
		setInput(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (input !== password && input !== "") {
			setError("sorry, that password is incorrect");
		}
	};

	// if (input !== password) {
	// 	return (
	// 		<div
	// 			className="container"
	// 			style={{
	// 				display: "flex",
	// 				flexDirection: "column",
	// 				justifyContent: "center",
	// 				alignItems: "center",
	// 				paddingTop: "4rem",
	// 			}}>
	// 			<h3>Welcome!</h3>
	// 			<p>Please enter the password given</p>
	// 			<form onSubmit={handleSubmit}>
	// 				<input
	// 					type="password"
	// 					autoComplete="off"
	// 					name="input"
	// 					onChange={(e) => handleInputChange(e)}
	// 				/>
	// 				<button
	// 					type="submit"
	// 					value="submit"
	// 					style={{
	// 						margin: "0.5rem",
	// 						padding: "0.25rem",
	// 						borderRadius: "10px",
	// 						borderColor: "grey",
	// 					}}>
	// 					Enter
	// 				</button>
	// 			</form>
	// 			<p>{error && error}</p>
	// 		</div>
	// 	);
	// }

	return (
		<>
			<Seo title="Portfolio" />

			<div className="container works__container">
				<h3>Welcome!</h3>
				<p>I'm glad you made it here.</p>
				<p>
					Here, I have demos of some works that can't be publicly shown, or more
					detailed walkthroughs of mentions on my resume.
				</p>
				<p>Feel free to have a browse at my demos below!</p>

				<details>
					<summary>
						Quote Builder Prototype with LLM, LangChain and OpenAI (2024)
					</summary>
					<div>
						<div>
							<iframe
								title="		Quote Builder Prototype with LLM, LangChain and OpenAI "
								src="https://www.loom.com/embed/7019087c71cd4f1abe73209ce3b0e9a9?sid=430f6e3a-ae68-45d8-be2c-987c0811f130"
								frameBorder="0"
								allowFullScreen></iframe>
						</div>
					</div>
				</details>
				<details>
					<summary>Generate and Send Feature Improvements (2024)</summary>
					<div>
						<div>
							<iframe
								src="https://www.loom.com/embed/f1281bf8477a4b1394e48d462c5b6fd7?sid=31fd74a2-59cf-45d7-834a-90911e55ca51"
								frameBorder="0"
								title="Generate and Send Feature Improvements"
								allowFullScreen></iframe>
						</div>
					</div>
				</details>
				<details>
					<summary>Ellis Don - Website Internationalization (2023)</summary>
					<div>
						<iframe
							src="https://www.loom.com/embed/e430300910974e54bdc8077a14311579?sid=f71dda17-42b8-406c-9955-c163e8fce405"
							title="Ellis Don - Website Internationalization"
							frameBorder="0"
							allowFullScreen></iframe>
					</div>
				</details>
			</div>
		</>
	);
};

export default Works;
